import useSWRMutation from 'swr/mutation';
import { creator } from '@utils/swr/creator';
import { getMutationOptions, type MutationOptions } from '@utils/swr/getMutationOptions';

export const defaultCreateHook =
  (url: string) =>
  (mutationOptions: MutationOptions = {}) =>
    useSWRMutation(url, creator, {
      ...getMutationOptions({
        populateCache: false,
        ...mutationOptions,
      }),
    });
